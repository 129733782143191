<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Visitor Details</h5>
            </div>
            <div class="card">
                <div class="p-d-flex p-ai-center p-flex-column p-flex-lg-row p-mb-1">
                    <div class="p-d-flex p-mr-3">
                        <Dropdown v-model="emp_name" :options="adminList" @change="getAdminVisitorsDetails($event)"
                            optionLabel="label" appendTo="body" class="p-text-capitalize" placeholder="Select admin"
                            :style="{ minWidth: '200px' }" />
                    </div>
                    <div class="p-d-flex p-mr-3">
                        <Dropdown v-model="host_name" :options="hostList" @change="getHostVisitorsDetails($event)"
                            optionLabel="label" appendTo="body" class="p-text-capitalize" placeholder="Select host"
                            :style="{ minWidth: '200px' }" />
                    </div>
                    <div class="p-d-flex ">
                        <Dropdown v-model="branch_name" :options="branchList" @change="getHostBranchVisitorsDetails($event)"
                            optionLabel="label" appendTo="body" class="p-text-capitalize" placeholder="Select branch"
                            :style="{ minWidth: '200px' }" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <TabView v-if="visitorsdatadisplayflag">
        <TabPanel header="Visitors Details">
            <div class="card">
                <DataTable :value="VisitorsDetailsList" :lazy="true" :paginator="true" :rows="30"
                    :totalRecords="VisitorsTotalRecords" :loading="loading" @page="onPageChange" class="p-datatable-users"
                    data-key="aa1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :style="{ 'overflow-wrap': 'break-word' }">
                    <template #empty> No data found. </template>
                    <template #loading> Loading data. Please wait... </template>
                    <Column header="Visitor Name" headerStyle="width: 25%">
                        <template #body="{ data }">
                            <div class="p-d-flex p-ai-center">
                                <div class="p-mr-3">
                                    <span v-if="data.aa15 != null && data.aa15 != ''">
                                        <img :src="data.aa15" width="50" height="50"
                                            style="vertical-align: middle; border-radius: 75px" />
                                    </span>
                                    <span v-else>
                                        <img src="/assets/layout/images/widgets/asset-profile-11.png" width="50" height="50"
                                            style="vertical-align: middle; border-radius: 75px" />
                                    </span>
                                </div>
                                <div>
                                    <div class="p-text-capitalize overflow-span">{{ data.aa9 ? data.aa9
                                        : 'N/A' }}</div>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Date" headerStyle="width: 16%">
                        <template #body="{ data }">
                            <span class="overflow-span">{{ data.aa26 ? format_timestamp(data.aa26) : "-" }}</span>
                        </template>
                    </Column>
                    <Column header="Email Id" headerStyle="width: 19%">
                        <template #body="{ data }">
                            <span class="overflow-span">{{ data.aa14 ? data.aa14 : "-" }}</span>
                        </template>
                    </Column>
                    <Column header=" Contact No." headerStyle="width: 11%">
                        <template #body="{ data }">
                            <span class="overflow-span">{{ data.aa13 ? data.aa13 : "-" }}</span>
                        </template>
                    </Column>
                    <Column header="Address" headerStyle="width: 22%">
                        <template #body="{ data }">
                            <span class="p-text-capitalize">{{ data.aa45 ? data.aa45 : "-" }}</span>
                        </template>
                    </Column>
                    <Column header="Type" headerStyle="width: 8%" class="p-text-center">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">
                                <div v-if="data.aa30 == 1"> Indian </div>
                                <div v-else-if="data.aa30 == 2"> Foreigner </div>
                            </div>
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </TabPanel>
    </TabView>
</template>
<script>
import ApiService from '../service/ApiService';
import moment from 'moment';

export default {
    data() {
        return {
            adminList: [],
            hostList: [],
            branchList: [],
            page_no: 0,
            VisitorsTotalRecords: 0,
            loading: false,
            VisitorsDetailsList: [],
            emp_name: '',
            host_name: '',
            branch_name: '',
            visitorsdatadisplayflag: false,
            admin_id: '',
            host_id: '',
            branch_id: '',
            admin_list_status: false,
            host_list_status: false,
            branch_list_status: false,
            itemsPerPage: 30
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.getAdminListOnly();
    },
    methods: {
        getAdminListOnly(e) {
            this.ApiService.getAdminListOnly(e).then((data) => {
                if (data.status == 200) {
                    this.adminList = data.data.records;
                    if (data.data.records.length == 1) {
                        this.getAdminVisitorsDetails({ value: data.data.records[0] });
                        this.emp_name = data.data.records[0];
                    }
                } else {
                    this.adminList = null;
                }
            });
        },
        getAdminVisitorsDetails(e) {
            this.visitorsdatadisplayflag = true;
            this.loading = true;
            if (e) {
                this.page_no = 0;
                this.admin_id = e.value.value;
            }
            this.ApiService.getAllVisitorsDetails({ clientId: this.admin_id, page_no: this.page_no, itemsPerPage: this.itemsPerPage }).then((data) => {
                if (data.success === true) {
                    this.VisitorsDetailsList = data.data.result;
                    this.VisitorsTotalRecords = data.data.count;
                    this.loading = false;
                    this.admin_list_status = true;
                    this.host_name = '';
                    this.branch_name = '';
                    if (e) {
                        this.getHostListOnly(e);
                    }
                }
            });
        },
        onPageChange(e) {
            this.loading = true;
            if (this.admin_list_status) {
                this.page_no = e.page;
                this.getAdminVisitorsDetails();
            }
            if (this.host_list_status) {
                this.page_no = e.page;
                this.getHostVisitorsDetails();
            }
            if (this.branch_list_status) {
                this.page_no = e.page;
                this.getHostBranchVisitorsDetails();
            }
        },
        getHostListOnly(e) {
            this.ApiService.getHostListOnly({ clientId: e.value.value }).then((data) => {
                if (data.status == 200) {
                    this.hostList = data.data.records;
                } else {
                    this.hostList = null;
                }
            });
        },
        getHostVisitorsDetails(e) {
            this.loading = true;
            if (e) {
                this.page_no = 0;
                this.host_id = e.value.value;
            }
            this.ApiService.getAllVisitorsDetailsByHost({ clientId: this.admin_id, hostId: this.host_id, page_no: this.page_no, itemsPerPage: this.itemsPerPage }).then((data) => {
                if (data.success === true) {
                    this.VisitorsDetailsList = data.data.result;
                    this.VisitorsTotalRecords = data.data.count;
                    this.loading = false;
                    this.visitorsdatadisplayflag = true;
                    this.admin_list_status = false;
                    this.host_list_status = true;
                    this.branch_name = '';
                    if (e) {
                        this.getHostBranchListOnly(e);
                    }
                }
            });
        },
        getHostBranchListOnly(e) {
            this.host_id = e.value.value;
            this.ApiService.getHostBranchesOnly({ clientId: this.admin_id, hostId: e.value.value }).then((data) => {
                if (data.status == 200) {
                    this.branchList = data.data;
                } else {
                    this.branchList = null;
                }
            });
        },
        getHostBranchVisitorsDetails(e) {
            if (e) {
                this.page_no = 0;
                this.branch_id = e.value.value;
            }
            this.loading = true;
            this.ApiService.getAllVisitorsDetailsByBranches({ clientId: this.admin_id, hostId: this.host_id, branchId: this.branch_id, page_no: this.page_no, itemsPerPage: this.itemsPerPage }).then((data) => {
                if (data.success === true) {
                    this.VisitorsDetailsList = data.data.result;
                    this.VisitorsTotalRecords = data.data.count;
                    this.loading = false;
                    this.visitorsdatadisplayflag = true;
                    this.admin_list_status = false;
                    this.host_list_status = true;
                    this.branch_list_status = true;
                }
            });
        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return 'N/A';
            }
        },
    },
};
</script>
<style scoped lang="scss"></style>
